h1,
h2,
h3,
h4,
h5 {
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 700;
}

h1 {
  font-size: 100px;

  @include bp-tablet {
    font-size: 60px;
  }
}

h2 {
  font-size: 60px;

  @include bp-tablet {
    font-size: 40px;
  }
}

h3 {
  font-size: 40px;
}

h4 {
  font-size: 24px;
  line-height: 27.6px;
}

h5 {
  font-size: 18px;
  line-height: 22.5px;
}

button {
  @apply px-[24px] h-12 flex justify-center items-center border-[1px] border-greenPrimary rounded-3xl;
  transition: all 0.2s ease;

  font-family: Gilroy, sans-serif;
  font-size: 16px;
  font-weight: 600;

  &.button--gray {
    @apply border-gray3;
  }

  &.button--error {
    @apply border-error;
  }

  &.button--white {
    @apply bg-white border-none text-black1;
  }
}

button:hover {
  @apply bg-greenPrimary;

  &.button--error {
    @apply bg-error;
  }

  &.button--gray {
    @apply bg-gray3;
  }

  &.button--white {
    @apply text-white;
  }
}

button:disabled {
  @apply bg-gray3 text-gray4 border-gray3;

  &.button--error {
    @apply bg-gray3 text-gray4 border-gray3;
  }
}

.spliced-block-gradient {
  background: linear-gradient(
      320deg,
      rgba(0, 0, 0, 0) 29.56%,
      rgba(103, 103, 103, 0.2) 42.41%,
      rgba(0, 0, 0, 0) 42.63%
    ),
    #0e0e15;

  &--gray {
    background: linear-gradient(
        320deg,
        rgba(0, 0, 0, 0) 25.12%,
        rgba(103, 103, 103, 0.2) 46.11%,
        rgba(0, 0, 0, 0) 46.46%
      ),
      #27282e;
  }

  &--green {
    background: linear-gradient(
        320deg,
        rgba(23, 198, 79, 0) 25.12%,
        rgba(23, 198, 79, 0.2) 46.11%,
        rgba(23, 198, 79, 0) 46.46%
      ),
      #0e0e15;
  }

  &--error {
    background: linear-gradient(
        320deg,
        rgba(236, 28, 28, 0) 25.12%,
        rgba(236, 28, 28, 0.2) 46.11%,
        rgba(236, 28, 28, 0) 46.46%
      ),
      #0e0e15;
  }

  &--edge {
    background: linear-gradient(
        320deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(103, 103, 103, 0.2) 11%,
        rgba(0, 0, 0, 0) 11%
      ),
      #0e0e15;
  }

  &--double {
    background: linear-gradient(
        320deg,
        rgba(0, 80, 85, 0) 72.46%,
        rgba(0, 80, 85, 0.2) 72.46%,
        rgba(0, 80, 85, 0) 82.4%
      ),
      linear-gradient(
        320deg,
        rgba(0, 80, 85, 0) 21.56%,
        rgba(0, 80, 85, 0.2) 27.44%,
        rgba(0, 80, 85, 0) 27.58%
      ),
      #0e0e15;
  }

  &-long {
    background: linear-gradient(
        305deg,
        rgba(0, 80, 85, 0) 18.34%,
        rgba(0, 80, 85, 0.2) 43.71%,
        rgba(0, 80, 85, 0) 44.14%
      ),
      #0e0e15;
  }
}

.container {
  position: relative;

  max-width: 1480px;
  width: 100%;

  margin-left: auto;
  margin-right: auto;

  padding: 0 20px;
}

.light-ellipse {
  @apply max-small:hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100%;
  transform: translate(-50%, -50%);
  z-index: -1;

  .light-ellipse__circle {
    // display: none; // todo

    position: absolute;

    width: 556px;
    height: 556px;
    border-radius: 556px;

    background: var(--colors, #005055);
    filter: blur(180px);
    opacity: 0.4;
  }
}

.indicator {
  @apply w-[24px] h-[12px] rounded-xl;
}

.notifier__notification {
  background: #ffffff;
  color: #0e0e15;
  max-width: 350px;

  &-message {
    font-size: 13px !important;
    line-height: 22px !important;
  }

  &--error {
    color: #ffffff;
    background: #ec1c1c;
  }

  &--success {
    color: #ffffff;
    background: #1bcd54;
  }
}

.input-group {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.input-control {
  width: 100%;
  padding: 17px 16px;
  outline: none;
  @apply border border-gray4 bg-black1 rounded-xl mb-4 text-body-2;

  &:-webkit-autofill {
    -webkit-text-fill-color: white !important;
    -webkit-box-shadow: 0 0 0 30px #0e0e15 inset !important;

    &:disabled {
      -webkit-box-shadow: 0 0 0 30px #27282e inset !important;
    }
  }

  &:disabled {
    @apply bg-gray1;
  }

  &__password-eye {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__success {
    @apply border-greenPrimary;
  }

  &__error {
    @apply border-error;
  }
}

div.input-control__error,
div.input-control__success {
  word-break: break-word;
  @apply mb-4 bg-error px-4 py-2 -mt-2 rounded text-caption flex;
}

div.input-control__success {
  @apply bg-greenPrimary;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  @apply bg-black1 text-body-2;
}
