@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    font-display: fallback;
    src: url($file-name + ".eot");
    src: url($file-name + ".woff") format("woff"),
      url($file-name + ".ttf") format("truetype");
    font-weight: $weight;
    font-style: $style;
  }
}

$break-points: (
  desktophd: 1439px,
  desktop: 1285px,
  desktopxs: 1199px,
  tablet: 991px,
  tabletxs: 767px,
  mobile: 599px,
  mobilexs: 359px,
);

// Adaptive mixins
@mixin mediaMax($mediaWidth) {
  @media only screen and (max-width: $mediaWidth - 1) {
    @content;
  }
}

@mixin mediaMin($mediaWidth) {
  @media only screen and (min-width: $mediaWidth) {
    @content;
  }
}

@mixin mediaDistance($mediaWidthMin, $mediaWidthMax) {
  @media only screen and (min-width: $mediaWidthMin) and (max-width: $mediaWidthMax - 1) {
    @content;
  }
}

@mixin bp-desktopHD($type: "max") {
  @media only screen and (#{$type}-width: map-get($break-points, desktophd)) {
    @content;
  }
}

@mixin bp-desktop($type: "max") {
  @media only screen and (#{$type}-width: map-get($break-points, desktop)) {
    @content;
  }
}

@mixin bp-desktopXS($type: "max") {
  @media only screen and (#{$type}-width: map-get($break-points, desktopxs)) {
    @content;
  }
}

@mixin bp-tablet($type: "max") {
  @media only screen and (#{$type}-width: map-get($break-points, tablet)) {
    @content;
  }
}

@mixin bp-tabletXS($type: "max") {
  @media only screen and (#{$type}-width: map-get($break-points, tabletxs)) {
    @content;
  }
}

@mixin bp-mobile($type: "max") {
  @media only screen and (#{$type}-width: map-get($break-points, mobile)) {
    @content;
  }
}

@mixin bp-mobileXS($type: "max") {
  @media only screen and (#{$type}-width: map-get($break-points, mobilexs)) {
    @content;
  }
}
